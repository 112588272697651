import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { withIntl } from '../i18n'

const Suksess = () => (
  <Layout>
    <section id="suksess" className="section is-medium is-success">
      <div className="container">
        <h2>Your mail is now sent</h2>
        <p>We will answer you as quick as we can.</p>
        <Link
          className="button is-primary is-medium"
          to="/"
          style={{ marginTop: '30px' }}
        >
          Go to the front page
        </Link>
      </div>
    </section>
  </Layout>
)

export default withIntl(Suksess)
